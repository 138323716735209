<template>
  <pro-menu-layout>
    <div class="q-ma-md">
      <pro-deck
        :title="getRes('System.Module.Shortlist.FinalResult')"
        :cards="tendererList"
        :statuses="statuses"
        v-slot="{ card, status }"
        filter-by-status
      >
        <pro-deck-card
          :title="card.tendererName"
          :status="status ? status.label : ''"
          :statusColor="status ? status.color : ''"
        />
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard },
  data() {
    return {
      tendererList: [],
      statuses: [
        {
          key: "Shortlist",
          label: this.getRes("System.Module.Shortlist.Shortlist"),
          color: "green",
        },
        {
          key: "NotShortlist",
          label: this.getRes("System.Module.Shortlist.NotShortlist"),
          color: "red",
        },
      ],
    };
  },
  created() {
    this.$proSmart.tender
      .getFinalShortlistTendererList(this, this.$route.params.id)
      .then((list) => {
        this.tendererList = list.map((tenderer) => ({
          ...tenderer,
          status: tenderer.isShortlist ? "Shortlist" : "NotShortlist",
        }));
      });
  },
};
</script>
